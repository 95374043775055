import React from 'react';
import Layout from '../../components/Layout';

import ProductsSearch from "../../components/products/ProductSearch"

export default function Products() {

    return (
        <Layout allowAnonymous={false}>
            <ProductsSearch/>
        </Layout>
    )
}