//TODO: Manufacturer search is not working properly
import React, {useEffect, useState} from 'react'
import {Paper, Table, TableBody, TableHead, TableFooter, TableRow, TablePagination, CircularProgress} from "@material-ui/core";
import {ProductDto,countProducts,findProducts} from "../../api/ProductApi";
import ProductPreview from "./ProductPreview";
import {navigate} from "gatsby";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import SearchStyled from "../common/SearchStyled";
import ButtonStyled from "../common/ButtonStyled";
import TableHeaderCellStyled from "../common/TableHeaderCellStyled";
import AutocompleteStyled from "../common/AutocompleteStyled";

interface ProductSearchProps{
    style?:any
}

export default function ProductSearch(props: ProductSearchProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true)
    const [totalProductsCount, setTotalProductsCount] = useState<number>(0);
    const [rows, setRows] = useState<ProductDto[]>([]);
    const [rowsPerPageLimit, setRowsPerPageLimit] = useState<number>(10);
    const [currentPageOffset, setCurrentPageOffset] = useState<number>(0);
    const [filterByNameContaining, setFilterByNameContaining] = useState("")
    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    //const [keywordsFull, setKeywordsFull] = useState<KeywordDto[]>([]);
    const [manufacturerFilter, setManufacturerFilter] = useState<string>(null);
    const [manufacturersFull, setManufacturersFull] = useState<ProductDto[]>([]);

    const getCurrentRows = (rowsPerPage, currentPage, nameContaining, manufacturerEqual, keywordsArray) => {
        findProducts(rowsPerPage, currentPage*rowsPerPage, nameContaining, null, manufacturerEqual, null, keywordsArray).then(data => {
            countProducts(nameContaining, null, manufacturerEqual, null, keywordsArray).then(countData => {
                setTotalProductsCount(countData)
            }).catch(err => console.log("Error getting total product count ",err))
            setRows(data)
            let tmpManufacturers = [];
            data.forEach(product => {
                if(tmpManufacturers.find(manufacturer => manufacturer.manufacturer==product.manufacturer)==undefined) tmpManufacturers.push(product);
            })
            setManufacturersFull(tmpManufacturers)
            setLoading(false)
        }).catch(err => console.log("Error getting product rows ",err))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        getCurrentRows(limit, 0, filterByNameContaining, manufacturerFilter, keywordFilter)
        setRowsPerPageLimit(limit);
        setCurrentPageOffset(0)
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        getCurrentRows(rowsPerPageLimit, newPage, filterByNameContaining, manufacturerFilter, keywordFilter)
        setCurrentPageOffset(newPage)
    };

    const handleFilterByName = (value: string) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, value, manufacturerFilter, keywordFilter)
        setFilterByNameContaining(value)
    }

    const handleFilterByManufacturer = (value: string) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, filterByNameContaining, value, keywordFilter)
        setManufacturerFilter(value)
    }

    /*
    const handleFilterByKeywords = (values: string[]) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, filterByNameContaining, manufacturerFilter, values)
        setKeywordFilter(values)
    }
    */

    const columns = [
        { key: 'id', label: t("ID"), width: "5%" },
        { key: 'name', label: t("PRODUCTNAME"), width: "20%" },
        { key: 'manufacturer', label: t("MANUFACTURERCOMPANY"), width: "15%"},
        { key: 'internalOrderNumber', label: t("ION"), width: "20%"},
        { key: 'lastChanged', label: t("LASTUPDATETIME"), width: "20%"},
        { key: 'userChanged', label: t("LASTUPDATEAUTHOR"), width: "20%"}
    ];

    useEffect(() => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, filterByNameContaining, manufacturerFilter, keywordFilter)
        /*findKeywords(null,null,null,null).then(data => {
            setKeywordsFull(data)
        }).catch(err => console.log("Error getting full product keywords ",err))*/
    },[]);

    if(loading){
        return (
            <div style={{display: 'flex', justifyContent: 'center',marginTop: 200}}><CircularProgress color="secondary"/></div>
        )
    }else{
    return (
        <Paper className={classes.root} style={props.style}>
            <div className={classes.filterContainer}>
                <SearchStyled text={t("SEARCHPRODUCTNAME")} value={filterByNameContaining} handleValueFunction={handleFilterByName}/>
                <ButtonStyled text={t("CREATENEW")} onClick={() => navigate("/products/create")}/>
                <div className={classes.flexBreak}/>
                {/* <AutocompleteStyled label="Keyword Search" value={keywordFilter} multiple={true} options={keywordsFull.map(keyword=>keyword.name)} handleValueFunction={handleFilterByKeywords} style={{width: "40%", minWidth: 300}}/> */}
                {/* <AutocompleteStyled label="Manufacturer Search" freeSolo={true} value={manufacturerFilter} multiple={false} options={manufacturersFull.map(manufacturer=>manufacturer.manufacturer)} handleValueFunction={handleFilterByManufacturer} style={{width: "30%", minWidth: 200}}/>*/}
            </div>
            <Table aria-label={t("PRODUCTSEARCHTABLE")}>
                <TableHead>
                    <TableRow key="products-search-header">
                        {columns.map((column) => (
                            <TableHeaderCellStyled key={column.key} width={column.width} label={column.label}/>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <ProductPreview key={row.id+row.name} id={row.id} name={row.name} manufacturer={row.manufacturer} internalOrderNumber={row.internalOrderNumber} lastChanged={row.lastChanged} userChanged={row.userChanged} />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow key="products-search-footer">
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30, { label: t("ALL"), value: 9999 }]}
                            style={{}}
                            count={totalProductsCount}
                            rowsPerPage={rowsPerPageLimit}
                            page={currentPageOffset}
                            SelectProps={{
                                inputProps: { 'aria-label': t("ROWSPERPAGE") },
                                native: true,
                            }}
                            labelRowsPerPage={t("ROWSPERPAGE")}
                            labelDisplayedRows={({ from, to, count }) =>from + '-' + to + t("ROWSOF") + count}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    )}
}
