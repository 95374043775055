import React from 'react'
import {TableCell, TableRow} from "@material-ui/core";
import {navigate} from "gatsby";

import { useStyles } from "../../styles/TableStyles";
import {KeywordDto} from "../../api/KeywordApi";

interface ProductPreviewProps{
    id: number,
    name: string,
    manufacturer?: string,
    keywords?: KeywordDto[],
    internalOrderNumber?: string,
    lastChanged?:string,
    userChanged?:string
}

export default function ProductPreview( props: ProductPreviewProps){
    const classes = useStyles();

    return (
        <TableRow onClick={()=>navigate("/products/" + props.id)} className={classes.childRowWithLink} hover>
            <TableCell align="left">
                {props.id}
            </TableCell>
            <TableCell align="left">
                {props.name}
            </TableCell>
            <TableCell align="left">
                {props.manufacturer}
            </TableCell>
            <TableCell align="left">
                {props.internalOrderNumber}
            </TableCell>
            <TableCell align="left">
                {props.lastChanged}
            </TableCell>
            <TableCell align="left">
                {props.userChanged}
            </TableCell>
        </TableRow>
    )
}